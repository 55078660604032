import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import axios from "axios";

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | Parça Burada Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file; // loader.file bir Promise nesnesi döndürür
  
            // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
            const body = new FormData();
            body.append('dataFile', file);
            console.log("file1c2313",file);
  
            try {
              const response = await axios.request({
                method: "POST",
                url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
                data: body, // axios'ta "data" kullanın, "body" değil
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
  
              resolve({
                default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
              });
            } catch (error) {
              // Hata oluştuğunda throw ile hata fırlatın
              throw error;
            }
  
            // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
          } catch (error) {
            reject(error);
          }
        });
      },
      abort: () => {}
    };
  }
  
  
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mail: (getContactEdit && getContactEdit.Company_MailAdress) || "",
    
    },
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {

      console.log("şknmsdşklf",getHtmlDataLong)
      const updateOrder = {
        ID: users ? users[0].ID : 0,

        Company_About: Base64.encode(getHtmlDataLong),
        Company_MailAdress : values.mail
      };
      // update order
      dispatch(onUpdateAbout(updateOrder));

      showToast(true)

      setTimeout(() =>  dispatch(onGetAbout()), 1000)


      validation.resetForm();

    },
  });

  const showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla güncellendi."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {

     
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    if (users.length > 0) {

      if(users[0].Company_About != null){
        setHtmlDataLong(Base64.decode(users[0].Company_About))
      }

      setContactEdit(users[0]);
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

          
                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataLong}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin]
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                              <Label className="form-label">Mail Adresi</Label>
                              <Input
                                name="mail"
                                label="mail"
                                type="text"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mail || ""}
                                invalid={
                                  validation.touched.mail &&
                                    validation.errors.mail
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mail &&
                                validation.errors.mail ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mail}
                                </FormFeedback>
                              ) : null}
                            </div>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
