import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMPANY_CATEGORY,DELETE_COMPANY_CATEGORY,ADD_COMPANY_CATEGORY,UPDATE_COMPANY_CATEGORY,
  GET_COMPANY_SUBCATEGORY,DELETE_COMPANY_SUBCATEGORY,ADD_COMPANY_SUBCATEGORY,UPDATE_COMPANY_SUBCATEGORY,
  GET_COMPANY_ADVERT_PRICE,DELETE_COMPANY_ADVERT_PRICE,ADD_COMPANY_ADVERT_PRICE,UPDATE_COMPANY_ADVERT_PRICE,
  GET_COMPANY,DELETE_COMPANY,ADD_COMPANY,UPDATE_COMPANY,
  GET_NE,DELETE_NE,ADD_NE,UPDATE_NE,
  GET_NEWS_CATEGORY,DELETE_NEWS_CATEGORY,ADD_NEWS_CATEGORY,UPDATE_NEWS_CATEGORY,
  GET_NEWS_COMMENT,DELETE_NEWS_COMMENT,
  GET_JOB_ADVERTISEMENT_SUBCATEGORY,DELETE_JOB_ADVERTISEMENT_SUBCATEGORY,ADD_JOB_ADVERTISEMENT_SUBCATEGORY,UPDATE_JOB_ADVERTISEMENT_SUBCATEGORY,
  GET_NEWS,DELETE_NEWS,ADD_NEWS,UPDATE_NEWS,
  GET_ADVERTESING_CATEGORY,DELETE_ADVERTESING_CATEGORY,ADD_ADVERTESING_CATEGORY,UPDATE_ADVERTESING_CATEGORY,
  GET_ADVERTESING_FORM,DELETE_ADVERTESING_FORM,ADD_ADVERTESING_FORM,UPDATE_ADVERTESING_FORM,
  GET_POOL,DELETE_POOL,ADD_POOL,UPDATE_POOL,
  GET_MAINPAGE_CATEGORY,DELETE_MAINPAGE_CATEGORY,ADD_MAINPAGE_CATEGORY,UPDATE_MAINPAGE_CATEGORY,
  GET_SONG,DELETE_SONG,ADD_SONG,UPDATE_SONG,
  GET_SONG_CATEGORY,DELETE_SONG_CATEGORY,ADD_SONG_CATEGORY,UPDATE_SONG_CATEGORY,
  GET_ARTICLEDAY,DELETE_ARTICLEDAY,ADD_ARTICLEDAY,UPDATE_ARTICLEDAY,
  GET_FORUM,DELETE_FORUM,ADD_FORUM,UPDATE_FORUM,
  GET_JOB_ADVERTISEMENT,DELETE_JOB_ADVERTISEMENT,
  GET_JOB_ADVERTISEMENT_CATEGORY,DELETE_JOB_ADVERTISEMENT_CATEGORY,ADD_JOB_ADVERTISEMENT_CATEGORY,UPDATE_JOB_ADVERTISEMENT_CATEGORY,
 GET_BLOGS, GET_SLİDERS,GET_SSS,DELETE_SSS,ADD_SSS,UPDATE_SSS, DELETE_DYNAMICFORM, DELETE_PRODUCTCATEGORY, DELETE_DYNAMICFORMEXTRA, DELETE_COURSESCHEDULES,
  DELETE_GALLERY, GET_DYNAMICFORM_EXTRA_EXTRA, GET_DYNAMICFORM_EXTRA, UPDATE_DYNAMICFORM, GET_CLASSES, GET_CLASSATTENDANCE,
  UPDATE_LESSONS_TIME, UPDATE_CONTACT, UPDATE_ABOUT, GET_TEACHER_WORKSHOP, GET_SUBPROJECT, UPDATE_REFERANCES, ADD_REFERANCES, GET_REFERANCES, ADD_WORKSHOP2, ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN, UPDATE_GALLERY, GET_GALLERY, UPDATE_WORKSHOP2, UPDATE_MEGA, UPDATE_AUTH, GET_AUTHORITY, GET_CONTACT,GET_ABOUT,
  UPDATE_WORKSHOPSESSION, UPDATE_SUBDOMAIN, ADD_COURSE, ADD_MEGA, GET_MEGA, GET_PROJECTSNEW, GET_DYNAMICFORM, GET_EVENT,
  GET_TeacherMega, GET_WorkshopSessionMega, GET_CourseSchedulesMega, GET_POPUP, GET_USERS, GET_USER_PROFILE,
   ADD_USER,DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_PRODUCTCATEGORY, ADD_SLIDER,
  ADD_PROJECT, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2,
  ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER,DELETE_BLOG, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY,
  ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, GET_EDUCATION, GET_SUBEDUCATION, UPDATE_EDUCATION, ADD_EDUCATION,
  UPDATE_SUBEDUCATION, ADD_SUBEDUCATION, DELETE_SUBEDUCATION, GET_PRODUCTCATEGORY, GET_PRODUCT, ADD_BLOG, UPDATE_PRODUCTCATEGORY,UPDATE_BLOG,ADD_PRODUCT,UPDATE_PRODUCT,DELETE_PRODUCT,
  GET_ABOUT2,
  UPDATE_ABOUT2,
  GET_FEEDBACK,
  DELETE_FEEDBACK,
  ADD_FEEDBACK,
  UPDATE_FEEDBACK,
  GET_DYNAMICFORM2,
  DELETE_DYNAMICFORM2,
  ADD_DYNAMIC_FORM2,
  UPDATE_DYNAMICFORM2
} from "./actionTypes"

import {

  addForumSuccess,
  updateForumSuccess,
  getForumSuccess,
  deleteForumSuccess,
  addArticleDaySuccess,
  updateArticleDaySuccess,
  getArticleDaySuccess,
  deleteArticleDaySuccess,

  addSongSuccess,
  updateSongSuccess,
  getSongSuccess,
  deleteSongSuccess,
  addNeSuccess,
  updateNeSuccess,
  getNeSuccess,
  deleteNeSuccess,


  addMainPage_CategorySuccess,
  updateMainPage_CategorySuccess,
  getMainPage_CategorySuccess,
  deleteMainPage_CategorySuccess,

  addSongCategorySuccess,
  updateSongCategorySuccess,
  getSongCategorySuccess,
  deleteSongCategorySuccess,

  getJob_AdvertisementSuccess,
  deleteJob_AdvertisementSuccess,

  addJob_Advertisement_CategorySuccess,
  updateJob_Advertisement_CategorySuccess,
  getJob_Advertisement_CategorySuccess,
  deleteJob_Advertisement_CategorySuccess,

  addJob_Advertisement_SubCategorySuccess,
  updateJob_Advertisement_SubCategorySuccess,
  getJob_Advertisement_SubCategorySuccess,
  deleteJob_Advertisement_SubCategorySuccess,

  addPoolSuccess,
  updatePoolSuccess,
  getPoolSuccess,
  deletePoolSuccess,


  addNewsSuccess,
  updateNewsSuccess,
  getNewsSuccess,
  deleteNewsSuccess,

  addAdvertesing_CategorySuccess,
  updateAdvertesing_CategorySuccess,
  getAdvertesing_CategorySuccess,
  deleteAdvertesing_CategorySuccess,


  addAdvertesing_FormSuccess,
  updateAdvertesing_FormSuccess,
  getAdvertesing_FormSuccess,
  deleteAdvertesing_FormSuccess,


  addCompanySuccess,
  updateCompanySuccess,
  getCompanySuccess,
  deleteCompanySuccess,


  addCompany_SubCategorySuccess,
  updateCompany_SubCategorySuccess,
  getCompany_SubCategorySuccess,
  deleteCompany_SubCategorySuccess,
  addCompany_CategorySuccess,
  updateCompany_CategorySuccess,
  getCompany_CategorySuccess,
  deleteCompany_CategorySuccess,

  getNews_CommentSuccess,
  deleteNews_CommentSuccess,

  addNews_CategorySuccess,
  updateNews_CategorySuccess,
  getNews_CategorySuccess,
  deleteNews_CategorySuccess,

  addCompany_Advert_PriceSuccess,
  updateCompany_Advert_PriceSuccess,
  getCompany_Advert_PriceSuccess,
  deleteCompany_Advert_PriceSuccess,


  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,


  addSliderSuccess,


  addSSSSuccess,
  updateSSSSuccess,
  getSSSSuccess,
  deleteSSSSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
 
  getMegaSuccess,
  getAboutSuccess,
  getTeacherSuccess,
  getDynamicSuccess,
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateContactSuccess,
  updateAboutSuccess,

  updateBlogSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,
  deleteProductCategorySuccess,

  deleteSliderSuccess,
  deleteBlogSuccess,
  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  getEducationSuccess,
  getSubEducationSuccess,
  updateEducationSuccess,
  addEducationSuccess,
  updateSubEducationSuccess,
  addSubEducationSuccess,
  deleteSubEducationSuccess,
  getProductCategorySuccess,
  getProductSuccess,
  getSlidersSuccess,
  getUsersSuccess,
  getBlogsSuccess,
  addProductCategorySuccess,
  addBlogSuccess,
  addProductSuccess,
  deleteProductSuccess,
  updateProductSuccess,
  addUserSuccess,
  getAbout2Success,
  updateAbout2Success,
  updateFeedBackSuccess,
  getFeedBackSuccess,
  deleteFeedBackSuccess,
  addFeedBackSuccess,
  addDynamicForm2Success,
  deleteDynamicForm2Success,
  updateDynamicForm2Success,
  getDynamicForm2Success,



  

} from "./actions"

//Include Both Helper File with needed methods
import {
  getNews_Category,addNews_Category,updateNews_Category,deleteNews_Category,
  getNe,addNe,updateNe,deleteNe,
  deleteNews_Comment,  getNews_Comment,
  getCompany_SubCategory,addCompany_SubCategory,updateCompany_SubCategory,deleteCompany_SubCategory,
  getCompany_Category,addCompany_Category,updateCompany_Category,deleteCompany_Category,
  getMainPage_Category,addMainPage_Category,updateMainPage_Category,deleteMainPage_Category,
  getCompany_Advert_Price,addCompany_Advert_Price,updateCompany_Advert_Price,deleteCompany_Advert_Price,
  getCompany,addCompany,updateCompany,deleteCompany,
  getJob_Advertisement,deleteJob_Advertisement,
  getJob_Advertisement_SubCategory,addJob_Advertisement_SubCategory,updateJob_Advertisement_SubCategory,deleteJob_Advertisement_SubCategory,
  getJob_Advertisement_Category,addJob_Advertisement_Category,updateJob_Advertisement_Category,deleteJob_Advertisement_Category,
  getAdvertesing_Category,addAdvertesing_Category,updateAdvertesing_Category,deleteAdvertesing_Category,
  getAdvertesing_Form,addAdvertesing_Form,updateAdvertesing_Form,deleteAdvertesing_Form,
  getSong,addSong,updateSong,deleteSong,
  getSongCategory,addSongCategory,updateSongCategory,deleteSongCategory,
  getForum,addForum,updateForum,deleteForum,
  getPool,addPool,updatePool,deletePool,
  getArticleDay,addArticleDay,updateArticleDay,deleteArticleDay,
  updateProduct,deleteProduct,addProduct ,updateProductCategory,updateAbout, getSSS,addSSS,updateSSS,deleteSSS,getBlogs, getCategory, deleteSubEducation, deleteBlog, deleteProductCategory, addSubEducation,addBlog, updateSubEducation, addEducation, updateEducation, getEducationSubItem, getEducation, deleteDynamicFormExtraExtra, deleteDynamicForm, deleteDynamicFormExtra, deleteCourseSchedules, addMultipleGalleryForm, deleteGallery, addGallerry, addProductCategory,
   deleteReferances, deletePopup, deleteSlider, deleteNews, deleteMega, getDynamicFormExtraExtraMega, getDynamicFormExtraMega, addDynamicForm, updateDynamicForm, getClassesMega, getClassAttendance, updateLessonsTime, addLessonsTime, getTeacherWorkshop, getSubProject, updateReferances, addReferances, getReferances, addWorkshop2, addAppSetting, addNewSubdomain, updateGallery, getGallery, updateWorkShop2, getWorkshop2, updateMega, updateProject, updateSlider, updateAuth, getAuthority, updateContact, getContact, getAbout,updateBlog, updateTeacher, updateWorkshopSession, updateSubdomain, addCourseMega, updateCourseSchedules, updateNews, addMega, addNews, addProject, addSlider, addPopup, getMegaMega, getNews, getProjectsNew, getSliders, getDynamicFormMega, getEventMega, getTeacherMega, getWorkshopSessionMega, getPopup, getUsers, getUserProfile, addNewUser, updateUser, deleteUser, getWorkShop, getCourseSchedulesMega, getProducts1, 
   addUser,
   getAbout2,
   updateAbout2,
   getFeedBack,
   updateFeedBack,
   deleteFeedBack,
   addFeedBack,
   getDynamicForm2,
   updateDynamicForm2,
   deleteDynamicForm2,
   addDynamicForm2} from "../../helpers/fakebackend_helper"




  function* onDeleteNews_Comment({ payload: user }) {
    try {
      const response = yield call(deleteNews_Comment, user)
      yield put(deleteNews_CommentSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }

  function* onGetNews_Comment(item) {
    try {
      const response = yield call(getNews_Comment, item.users)
      yield put(getNews_CommentSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }



  function* onDeleteNews_Category({ payload: user }) {
    try {
      const response = yield call(deleteNews_Category, user)
      yield put(deleteNews_CategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddNews_Category({ payload: user }) {
  
    try {
      const response = yield call(addNews_Category, user)
  
      yield put(addNews_CategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateNews_Category({ payload: user }) {
    try {
      const response = yield call(updateNews_Category, user)
      yield put(updateNews_CategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetNews_Category(item) {
    try {
      const response = yield call(getNews_Category, item.users)
      yield put(getNews_CategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }
  function* onGetNews(item) {
    try {
      const response = yield call(getNews, item.users)
      yield put(getNewsSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }
  function* onUpdateNews({ payload: user }) {
    try {
      const response = yield call(updateNews, user)
      console.log("sşlmdflşsd",response.value)
      yield put(updateNewsSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onAddNews({ payload: user }) {
  
    try {
      const response = yield call(addNews, user)
  
      yield put(addNewsSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }

  function* onDeleteJob_Advertisement_SubCategory({ payload: user }) {
    try {
      const response = yield call(deleteJob_Advertisement_SubCategory, user)
      yield put(deleteJob_Advertisement_SubCategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddJob_Advertisement_SubCategory({ payload: user }) {
  
    try {
      const response = yield call(addJob_Advertisement_SubCategory, user)
  
      yield put(addJob_Advertisement_SubCategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateJob_Advertisement_SubCategory({ payload: user }) {
    try {
      const response = yield call(updateJob_Advertisement_SubCategory, user)
      yield put(updateJob_Advertisement_SubCategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetJob_Advertisement_SubCategory(item) {
    try {
      const response = yield call(getJob_Advertisement_SubCategory, item.users)
      yield put(getJob_Advertisement_SubCategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }







  function* onDeleteNe({ payload: user }) {
    try {
      const response = yield call(deleteNe, user)
      yield put(deleteNeSuccess(user,response))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddNe({ payload: user }) {
  
    try {
      const response = yield call(addNe, user)
  
      yield put(addNeSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateNe({ payload: user }) {
    try {
      const response = yield call(updateNe, user)
      console.log("şlmsdşlfl",response.value)
      yield put(updateNeSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetNe(item) {
    try {
      const response = yield call(getNe, item.users)
      yield put(getNeSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }



  function* onDeleteMainPage_Category({ payload: user }) {
    try {
      const response = yield call(deleteMainPage_Category, user)
      yield put(deleteMainPage_CategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddMainPage_Category({ payload: user }) {
  
    try {
      const response = yield call(addMainPage_Category, user)
  
      yield put(addMainPage_CategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateMainPage_Category({ payload: user }) {
    try {
      const response = yield call(updateMainPage_Category, user)
      yield put(updateMainPage_CategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetMainPage_Category(item) {
    try {
      const response = yield call(getMainPage_Category, item.users)
      yield put(getMainPage_CategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }




  function* onDeleteJob_Advertisement({ payload: user }) {
    try {
      const response = yield call(deleteJob_Advertisement, user)
      yield put(deleteJob_AdvertisementSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  function* onGetJob_Advertisement(item) {
    try {
      const response = yield call(getJob_Advertisement, item.users)
      yield put(getJob_AdvertisementSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }



  function* onDeleteJob_Advertisement_Category({ payload: user }) {
    try {
      const response = yield call(deleteJob_Advertisement_Category, user)
      yield put(deleteJob_Advertisement_CategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddJob_Advertisement_Category({ payload: user }) {
  
    try {
      const response = yield call(addJob_Advertisement_Category, user)
  
      yield put(addJob_Advertisement_CategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateJob_Advertisement_Category({ payload: user }) {
    try {
      const response = yield call(updateJob_Advertisement_Category, user)
      yield put(updateJob_Advertisement_CategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetJob_Advertisement_Category(item) {
    try {
      const response = yield call(getJob_Advertisement_Category, item.users)
      yield put(getJob_Advertisement_CategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }




  function* onDeleteForum({ payload: user }) {
    try {
      const response = yield call(deleteForum, user)
      yield put(deleteForumSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddForum({ payload: user }) {
  
    try {
      const response = yield call(addForum, user)
  
      yield put(addForumSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateForum({ payload: user }) {
    try {
      const response = yield call(updateForum, user)
      yield put(updateForumSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetForum(item) {
    try {
      const response = yield call(getForum, item.users)
      yield put(getForumSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }






  function* onDeleteAdvertesing_Category({ payload: user }) {
    try {
      const response = yield call(deleteAdvertesing_Category, user)
      yield put(deleteAdvertesing_CategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddAdvertesing_Category({ payload: user }) {
  
    try {
      const response = yield call(addAdvertesing_Category, user)
  
      console.log("jknsdlf",response.value)
      yield put(addAdvertesing_CategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateAdvertesing_Category({ payload: user }) {
    try {
      const response = yield call(updateAdvertesing_Category, user)
      yield put(updateAdvertesing_CategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetAdvertesing_Category(item) {
    try {
      const response = yield call(getAdvertesing_Category, item.users)
      yield put(getAdvertesing_CategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }







  function* onDeleteArticleDay({ payload: user }) {
    try {
      const response = yield call(deleteArticleDay, user)
      yield put(deleteArticleDaySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddArticleDay({ payload: user }) {
  
    try {
      const response = yield call(addArticleDay, user)
  
      yield put(addArticleDaySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateArticleDay({ payload: user }) {
    try {
      const response = yield call(updateArticleDay, user)
      yield put(updateArticleDaySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetArticleDay(item) {
    try {
      const response = yield call(getArticleDay, item.users)
      yield put(getArticleDaySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }









  function* onDeleteAdvertesing_Form({ payload: user }) {
    try {
      const response = yield call(deleteAdvertesing_Form, user)
      yield put(deleteAdvertesing_FormSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddAdvertesing_Form({ payload: user }) {
  
    try {
      const response = yield call(addAdvertesing_Form, user)
  
      yield put(addAdvertesing_FormSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateAdvertesing_Form({ payload: user }) {
    try {
      const response = yield call(updateAdvertesing_Form, user)
      yield put(updateAdvertesing_FormSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetAdvertesing_Form(item) {
    try {
      const response = yield call(getAdvertesing_Form, item.users)
      yield put(getAdvertesing_FormSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }






  function* onDeletePool({ payload: user }) {
    try {
      const response = yield call(deletePool, user)
      yield put(deletePoolSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddPool({ payload: user }) {
  
    try {
      const response = yield call(addPool, user)
  
      yield put(addPoolSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdatePool({ payload: user }) {
    try {
      const response = yield call(updatePool, user)
      yield put(updatePoolSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetPool(item) {
    try {
      const response = yield call(getPool, item.users)
      yield put(getPoolSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }



  function* onDeleteSongCategory({ payload: user }) {
    try {
      const response = yield call(deleteSongCategory, user)
      yield put(deleteSongCategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddSongCategory({ payload: user }) {
  
    try {
      const response = yield call(addSongCategory, user)
  
      yield put(addSongCategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateSongCategory({ payload: user }) {
    try {
      const response = yield call(updateSongCategory, user)
      yield put(updateSongCategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetSongCategory(item) {
    try {
      const response = yield call(getSongCategory, item.users)
      yield put(getSongCategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }






  function* onDeleteCompany_Advert_Price({ payload: user }) {
    try {
      const response = yield call(deleteCompany_Advert_Price, user)
      yield put(deleteCompany_Advert_PriceSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddCompany_Advert_Price({ payload: user }) {
  
    try {
      const response = yield call(addCompany_Advert_Price, user)
  
      yield put(addCompany_Advert_PriceSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateCompany_Advert_Price({ payload: user }) {
    try {
      const response = yield call(updateCompany_Advert_Price, user)
      yield put(updateCompany_Advert_PriceSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetCompany_Advert_Price(item) {
    try {
      const response = yield call(getCompany_Advert_Price, item.users)
      yield put(getCompany_Advert_PriceSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }








  function* onDeleteSong({ payload: user }) {
    try {
      const response = yield call(deleteSong, user)
      yield put(deleteSongSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddSong({ payload: user }) {
  
    try {
      const response = yield call(addSong, user)
  
      yield put(addSongSuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateSong({ payload: user }) {
    try {
      const response = yield call(updateSong, user)
      yield put(updateSongSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetSong(item) {
    try {
      const response = yield call(getSong, item.users)
      yield put(getSongSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }








  function* onDeleteCompany({ payload: user }) {
    try {
      const response = yield call(deleteCompany, user)
      yield put(deleteCompanySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddCompany({ payload: user }) {
  
    try {
      const response = yield call(addCompany, user)
  
      yield put(addCompanySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateCompany({ payload: user }) {
    try {
      const response = yield call(updateCompany, user)
      yield put(updateCompanySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetCompany(item) {
    try {
      const response = yield call(getCompany, item.users)
      yield put(getCompanySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }


  function* onDeleteCompany_SubCategory({ payload: user }) {
    try {
      const response = yield call(deleteCompany_SubCategory, user)
      yield put(deleteCompany_SubCategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddCompany_SubCategory({ payload: user }) {
  
    try {
      const response = yield call(addCompany_SubCategory, user)
  
      yield put(addCompany_SubCategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateCompany_SubCategory({ payload: user }) {
    try {
      const response = yield call(updateCompany_SubCategory, user)
      yield put(updateCompany_SubCategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetCompany_SubCategory(item) {
    try {
      const response = yield call(getCompany_SubCategory, item.users)
      yield put(getCompany_SubCategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }




  function* onDeleteCompany_Category({ payload: user }) {
    try {
      const response = yield call(deleteCompany_Category, user)
      yield put(deleteCompany_CategorySuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  
  function* onAddCompany_Category({ payload: user }) {
  
    try {
      const response = yield call(addCompany_Category, user)
  
      yield put(addCompany_CategorySuccess(response.value[0]))
    } catch (error) {
  
      
    }
  }
  
  function* onUpdateCompany_Category({ payload: user }) {
    try {
      const response = yield call(updateCompany_Category, user)
      yield put(updateCompany_CategorySuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }
  
  function* onGetCompany_Category(item) {
    try {
      const response = yield call(getCompany_Category, item.users)
      yield put(getCompany_CategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }














function* onDeleteSubEducation({ payload: user }) {
  try {
    const response = yield call(deleteSubEducation, user)
    yield put(deleteSubEducationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


















function* onDeleteProduct({ payload: user }) {
  try {
    const response = yield call(deleteProduct, user)
    yield put(deleteProductSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddProduct({ payload: user }) {

  try {
    const response = yield call(addProduct, user)

    yield put(addProductSuccess(response.value[0]))
  } catch (error) {

    
  }
}


function* onUpdateProduct({ payload: user }) {
  try {
    const response = yield call(updateProduct, user)
    yield put(updateProductSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteSss({ payload: user }) {
  try {
    const response = yield call(deleteSSS, user)
    yield put(deleteSSSSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSSS({ payload: user }) {

  try {
    const response = yield call(addSSS, user)

    yield put(addSSSSuccess(response.value[0]))
  } catch (error) {

    
  }
}

function* onUpdateSSS({ payload: user }) {
  try {
    const response = yield call(updateSSS, user)
    yield put(updateSSSSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetSSS(item) {
  try {
    const response = yield call(getSSS, item.users)
    yield put(getSSSSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}




function* onDeleteBlog({ payload: user }) {
  try {
    const response = yield call(deleteBlog, user)
    yield put(deleteBlogSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSubEducation({ payload: user }) {

  try {
    const response = yield call(addSubEducation, user)

    yield put(addSubEducationSuccess(response.value[0]))
  } catch (error) {

    
  }
}


function* onUpdateSubEducation({ payload: user }) {
  try {
    const response = yield call(updateSubEducation, user)
    yield put(updateSubEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateEducation({ payload: user }) {
  try {
    const response = yield call(updateEducation, user)
    yield put(updateEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(updateContactSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateAbout({ payload: user }) {
  try {
    const response = yield call(updateAbout, user)
    yield put(updateAboutSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    console.log("şlmşlf",response.value)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    console.log("kmsdlkşf",error)
    yield put(updateUserFail(error))
  }
}

function* onGetEducationSubItem(item) {
  try {
    const response = yield call(getEducationSubItem, item.users)
    yield put(getSubEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetEducation(item) {
  try {
    const response = yield call(getEducation, item.users)
    yield put(getEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* ongetProductCategory(item) {
  try {
    const response = yield call(getCategory, item.users)
    console.log("response", response)
    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetProduct(item) {
  try {
    const response = yield call(getProducts1, item.users)
    yield put(getProductSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetSliders(item) {
  try {
    const response = yield call(getSliders, item.users)
    yield put(getSlidersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetBlogs(item) {
  try {
    const response = yield call(getBlogs, item.users)
    console.log("kbsdf", response)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetUsers(item) {
  try {
    const response = yield call(getUsers, item.users)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance() {
  try {
    const response = yield call(getClassAttendance)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetTeacherWorkshopMega() {
  try {
    const response = yield call(getTeacherWorkshop)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject(item.news))
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)
    yield put(updateLessonsTimeSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onAddEducation({ payload: user }) {

  try {
    const response = yield call(addEducation, user)

    yield put(addEducationSuccess(response.value[0]))
  } catch (error) {

    
  }
}





function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAbout() {
  try {
    const response = yield call(getAbout)
    yield put(getAboutSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAbout2() {
  try {
    const response = yield call(getAbout2)
    console.log("lşmsdlşif",response)
    yield put(getAbout2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}



function* onGetDynamicForm2(item) {
  try {
    const response = yield call(getDynamicForm2, item.users)
    yield put(getDynamicForm2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateDynamicForm2({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm2, user)
    yield put(updateDynamicForm2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteDynamicForm2({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm2, user)
    yield put(deleteDynamicForm2Success(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onaddDynamicForm2({ payload: user }) {
  try {
    const response = yield call(addDynamicForm2, user)

    yield put(addDynamicForm2Success(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetFeedBack(item) {
  try {
    const response = yield call(getFeedBack, item.users)
    yield put(getFeedBackSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateFeedBack({ payload: user }) {
  try {
    const response = yield call(updateFeedBack, user)
    yield put(updateFeedBackSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteFeedBack({ payload: user }) {
  try {
    const response = yield call(deleteFeedBack, user)
    yield put(deleteFeedBackSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddFeedBack({ payload: user }) {
  try {
    const response = yield call(addFeedBack, user)

    yield put(addFeedBackSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateAbout2({ payload: user }) {
  try {
    const response = yield call(updateAbout2, user)
    yield put(updateAbout2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateBlog({ payload: user }) {
  try {
    const response = yield call(updateBlog, user)
    yield put(updateBlogSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onUpdateProductCategory({ payload: user }) {
  try {
    const response = yield call(updateProductCategory, user)
    console.log("jlnsdfl", response)
    yield put(updateSuccessProductCategory(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega() {
  try {
    const response = yield call(getTeacherMega)
    yield put(getTeacherSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    console.log("response123", response)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateSuccessCourseSchedules(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteUserSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}




function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    
  }
}

function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    console.log("respocccc13", response.value[0])
    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    
  }
}



function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}
function* onAddSlider({ payload: user }) {

  try {
    const response = yield call(addSlider, user)

    console.log("response123213", response)
    yield put(addSliderSuccess(response.value[0]))
  } catch (error) {

    
  }
}

function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    console.log("user123", user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductCategory, user)
    console.log("user123", user)
    yield put(deleteProductCategorySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    const response = yield call(deleteNews, user)
    console.log("user123", user)
    yield put(deleteNewsSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    console.log("user123", user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123", user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123", user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    
  }
}


function* onAddProductCategory({ payload: user }) {

  try {
    const response = yield call(addProductCategory, user)

    yield put(addProductCategorySuccess(response.value[0]))
  } catch (error) {

    
  }
}




function* onAddUser({ payload: user }) {

  try {
    const response = yield call(addUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    
  }
}

function* onAddBlog({ payload: user }) {

  try {
    const response = yield call(addBlog, user)

    yield put(addBlogSuccess(response.value[0]))
  } catch (error) {

    
  }
}


function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

    //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* contactsSaga() {
  

yield takeEvery(GET_DYNAMICFORM2, onGetDynamicForm2)
yield takeEvery(DELETE_DYNAMICFORM2, onDeleteDynamicForm2)
yield takeEvery(ADD_DYNAMIC_FORM2, onaddDynamicForm2)
yield takeEvery(UPDATE_DYNAMICFORM2, onUpdateDynamicForm2)

  yield takeEvery(GET_FEEDBACK, onGetFeedBack)
  yield takeEvery(DELETE_FEEDBACK, onDeleteFeedBack)
  yield takeEvery(ADD_FEEDBACK, onAddFeedBack)
  yield takeEvery(UPDATE_FEEDBACK, onUpdateFeedBack)

  yield takeEvery(ADD_USER, onAddUser)

  yield takeEvery(DELETE_SUBEDUCATION, onDeleteSubEducation)


  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(ADD_PRODUCT, onAddProduct)

  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)

  

  yield takeEvery(DELETE_BLOG, onDeleteBlog)

  yield takeEvery(DELETE_SSS, onDeleteSss)
  yield takeEvery(ADD_SSS, onAddSSS)
  yield takeEvery(GET_SSS, onGetSSS)
  yield takeEvery(UPDATE_SSS, onUpdateSSS)

  yield takeEvery(DELETE_COMPANY_CATEGORY, onDeleteCompany_Category)
  yield takeEvery(ADD_COMPANY_CATEGORY, onAddCompany_Category)
  yield takeEvery(GET_COMPANY_CATEGORY, onGetCompany_Category)
  yield takeEvery(UPDATE_COMPANY_CATEGORY, onUpdateCompany_Category)

  yield takeEvery(DELETE_COMPANY_SUBCATEGORY, onDeleteCompany_SubCategory)
  yield takeEvery(ADD_COMPANY_SUBCATEGORY, onAddCompany_SubCategory)
  yield takeEvery(GET_COMPANY_SUBCATEGORY, onGetCompany_SubCategory)
  yield takeEvery(UPDATE_COMPANY_SUBCATEGORY, onUpdateCompany_SubCategory)

  yield takeEvery(DELETE_NEWS_CATEGORY, onDeleteNews_Category)
  yield takeEvery(ADD_NEWS_CATEGORY, onAddNews_Category)
  yield takeEvery(GET_NEWS_CATEGORY, onGetNews_Category)
  yield takeEvery(UPDATE_NEWS_CATEGORY, onUpdateNews_Category)

  yield takeEvery(DELETE_NEWS_COMMENT, onDeleteNews_Comment)

  yield takeEvery(GET_NEWS_COMMENT, onGetNews_Comment)


  yield takeEvery(DELETE_JOB_ADVERTISEMENT, onDeleteJob_Advertisement)

  yield takeEvery(GET_JOB_ADVERTISEMENT, onGetJob_Advertisement)

  yield takeEvery(DELETE_COMPANY_ADVERT_PRICE, onDeleteCompany_Advert_Price)
  yield takeEvery(ADD_COMPANY_ADVERT_PRICE, onAddCompany_Advert_Price)
  yield takeEvery(GET_COMPANY_ADVERT_PRICE, onGetCompany_Advert_Price)
  yield takeEvery(UPDATE_COMPANY_ADVERT_PRICE, onUpdateCompany_Advert_Price)

  yield takeEvery(DELETE_FORUM, onDeleteForum)
  yield takeEvery(ADD_FORUM, onAddForum)
  yield takeEvery(GET_FORUM, onGetForum)
  yield takeEvery(UPDATE_FORUM, onUpdateForum)

  yield takeEvery(DELETE_ARTICLEDAY, onDeleteArticleDay)
  yield takeEvery(ADD_ARTICLEDAY, onAddArticleDay)
  yield takeEvery(GET_ARTICLEDAY, onGetArticleDay)
  yield takeEvery(UPDATE_ARTICLEDAY, onUpdateArticleDay)

  yield takeEvery(DELETE_NE, onDeleteNe)
  yield takeEvery(ADD_NE, onAddNe)
  yield takeEvery(GET_NE, onGetNe)
  yield takeEvery(UPDATE_NE, onUpdateNe)

  yield takeEvery(DELETE_ADVERTESING_CATEGORY, onDeleteAdvertesing_Category)
  yield takeEvery(ADD_ADVERTESING_CATEGORY, onAddAdvertesing_Category)
  yield takeEvery(GET_ADVERTESING_CATEGORY, onGetAdvertesing_Category)
  yield takeEvery(UPDATE_ADVERTESING_CATEGORY, onUpdateAdvertesing_Category)




  yield takeEvery(DELETE_ADVERTESING_FORM, onDeleteAdvertesing_Form)
  yield takeEvery(ADD_ADVERTESING_FORM, onAddAdvertesing_Form)
  yield takeEvery(GET_ADVERTESING_FORM, onGetAdvertesing_Form)
  yield takeEvery(UPDATE_ADVERTESING_FORM, onUpdateAdvertesing_Form)

  yield takeEvery(DELETE_POOL, onDeletePool)
  yield takeEvery(ADD_POOL, onAddPool)
  yield takeEvery(GET_POOL, onGetPool)
  yield takeEvery(UPDATE_POOL, onUpdatePool)


  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(ADD_COMPANY, onAddCompany)
  yield takeEvery(GET_COMPANY, onGetCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)

 
  yield takeEvery(DELETE_SONG, onDeleteSong)
  yield takeEvery(ADD_SONG, onAddSong)
  yield takeEvery(GET_SONG, onGetSong)
  yield takeEvery(UPDATE_SONG, onUpdateSong)



   
  yield takeEvery(DELETE_NEWS, onDeleteNews)
  yield takeEvery(ADD_NEWS, onAddNews)
  yield takeEvery(GET_NEWS, onGetNews)
  yield takeEvery(UPDATE_NEWS, onUpdateNews)


  yield takeEvery(DELETE_MAINPAGE_CATEGORY, onDeleteMainPage_Category)
  yield takeEvery(ADD_MAINPAGE_CATEGORY, onAddMainPage_Category)
  yield takeEvery(GET_MAINPAGE_CATEGORY, onGetMainPage_Category)
  yield takeEvery(UPDATE_MAINPAGE_CATEGORY, onUpdateMainPage_Category)



  yield takeEvery(DELETE_JOB_ADVERTISEMENT_CATEGORY, onDeleteJob_Advertisement_Category)
  yield takeEvery(ADD_JOB_ADVERTISEMENT_CATEGORY, onAddJob_Advertisement_Category)
  yield takeEvery(GET_JOB_ADVERTISEMENT_CATEGORY, onGetJob_Advertisement_Category)
  yield takeEvery(UPDATE_JOB_ADVERTISEMENT_CATEGORY, onUpdateJob_Advertisement_Category)




  yield takeEvery(DELETE_JOB_ADVERTISEMENT_SUBCATEGORY, onDeleteJob_Advertisement_SubCategory)
  yield takeEvery(ADD_JOB_ADVERTISEMENT_SUBCATEGORY, onAddJob_Advertisement_SubCategory)
  yield takeEvery(GET_JOB_ADVERTISEMENT_SUBCATEGORY, onGetJob_Advertisement_SubCategory)
  yield takeEvery(UPDATE_JOB_ADVERTISEMENT_SUBCATEGORY, onUpdateJob_Advertisement_SubCategory)


  yield takeEvery(DELETE_SONG_CATEGORY, onDeleteSongCategory)
  yield takeEvery(ADD_SONG_CATEGORY, onAddSongCategory)
  yield takeEvery(GET_SONG_CATEGORY, onGetSongCategory)
  yield takeEvery(UPDATE_SONG_CATEGORY, onUpdateSongCategory)

  yield takeEvery(ADD_SUBEDUCATION, onAddSubEducation)

  yield takeEvery(UPDATE_SUBEDUCATION, onUpdateSubEducation)

  yield takeEvery(ADD_EDUCATION, onAddEducation)

  yield takeEvery(UPDATE_EDUCATION, onUpdateEducation)

  yield takeEvery(GET_SUBEDUCATION, onGetEducationSubItem)

  yield takeEvery(GET_EDUCATION, onGetEducation)
  yield takeEvery(GET_PRODUCTCATEGORY, ongetProductCategory)

  yield takeEvery(GET_PRODUCT, onGetProduct)

  yield takeEvery(GET_SLİDERS, onGetSliders)
  yield takeEvery(GET_BLOGS, onGetBlogs)

  yield takeEvery(GET_USERS, onGetUsers)

  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)


  yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory)

  yield takeEvery(ADD_BLOG, onAddBlog)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)
  yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategory)

  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_BLOG, onUpdateBlog)
  yield takeEvery(UPDATE_PRODUCTCATEGORY, onUpdateProductCategory)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(UPDATE_ABOUT, onUpdateAbout)

  yield takeEvery(UPDATE_ABOUT2, onUpdateAbout2)

  yield takeEvery(GET_CONTACT, fetchContact)
  yield takeEvery(GET_ABOUT, fetchAbout)
  yield takeEvery(GET_ABOUT2, fetchAbout2)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)




  yield takeEvery(ADD_COURSE, onAddCourse)


  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)


  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)

  
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
