import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname } from "./contactlistCol";
import { QRCodeCanvas } from 'qrcode.react';


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";


import Dropzone from 'react-dropzone-uploader';

import {
  getUsers as onGetUser,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";


function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcılar | Parça Burada ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [contactEditQr, setEditContactQr] = useState();

  const [getFileName, setFileName] = useState(null);
  const [getIsActive, setIsActive] = useState(false);

  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const qrRef = useRef();  // QR kodu referans ile saklamak için

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector('canvas'); // Canvas öğesini seç
    const imageURL = canvas.toDataURL('image/png'); // QR kodunu PNG formatında veri URL'si olarak al
    const link = document.createElement('a'); // İndirme bağlantısını oluştur
    link.href = imageURL;
    link.download = 'qrcode.png'; // Dosya adı
    link.click(); // Dosya indirme işlemini başlat
  };
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Users_Name_Surname) || "",
      mail: (contactEdit && contactEdit.Users_MailAddress) || "",
      phone: (contactEdit && contactEdit.Users_WhatsappNumber) || "",
      password : (contactEdit && contactEdit.Users_Password) || ""
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Adını Giriniz"),
      phone: Yup.string(3).required("Telefonunu Giriniz"),
      password: Yup.string(3).required("Şifreyi Giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Users_Name_Surname: values.title,
          Users_WhatsappNumber: values.phone,
          Users_MailAddress: values.mail,
          Users_Profile_Photo:getFileName,
          Users_Password:values.password,
          Users_Type_ID:getCategoryID,
          Users_Is_Active:getIsActive
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Users_Name_Surname: values["title"],
          Users_WhatsappNumber: values["phone"],
          Users_MailAddress:  values["mail"],
          Users_Profile_Photo:getFileName,
          Users_Password:values["password"],
          Users_Type_ID:getCategoryID,
          Users_Is_Active:getIsActive
        };
        // save new user
        dispatch(onAddUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalQr, setModalQr] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);

  const exportPDFDiagnostic = (users1) => {
    // HTML içeriği görünmez bir div içine ekliyoruz
    const content = document.createElement("div");
    content.style.position = "absolute";
    content.style.top = "-9999px"; // Sayfada görünmez
    content.innerHTML = `
      <div style="font-family: Arial, sans-serif;">
        <h2>Kullanıcı Excel</h2>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>K.Adi</th>
              <th>S.Cevap</th>
              <th>Soru</th>
              <th>Tarih</th>
              <th>Y.Cevap</th>
            </tr>
          </thead>
          <tbody>
            ${users1.Users_Complate_Diagnostic_Form.map(elt => `
              <tr>
                <td>${users1.Customer_NameSurname}</td>
                <td>${elt.Diagnostic_Form_Answer ? elt.Diagnostic_Form_Answer.Diagnostic_Form_Answer_Title : ""}</td>
                <td>${elt.Diagnostic_Form  ? elt.Diagnostic_Form.Diagnostic_Form_Title: ""}</td>
                <td>${moment(elt.CreatedDateTime).format('DD/MM/YYYY')}</td>
                <td>${elt.Answer_Text || ""}</td>
              </tr>
            `).join("")}
          </tbody>
        </table>
      </div>
    `;
  
    document.body.appendChild(content); // İçeriği sayfaya ekliyoruz
  
    // İçerik yüklendikten sonra html2canvas'i çalıştırıyoruz
    html2canvas(content).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // A4 genişliğine göre ayarlandı
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save(`${users1.Customer_NameSurname}_report.pdf`);
  
      document.body.removeChild(content); // İşlemden sonra görünmez div'i kaldırıyoruz
    }).catch(error => {
      console.error("PDF oluşturulurken hata oluştu:", error);
    });
  };
  const exportPDF = (users1) => {
    // HTML içeriği görünmez bir div içine ekliyoruz
    const content = document.createElement("div");
    content.style.position = "absolute";
    content.style.top = "-9999px"; // Sayfada görünmez
    content.innerHTML = `
      <div style="font-family: Arial, sans-serif;">
        <h2>Kullanıcı Excel</h2>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>K.Adi</th>
              <th>S.Cevap</th>
              <th>Soru</th>
              <th>Tarih</th>
              <th>Y.Cevap</th>
            </tr>
          </thead>
          <tbody>
            ${users1.Users_ComplatePool.map(elt => `
              <tr>
                <td>${users1.Customer_NameSurname}</td>
                <td>${elt.Pool_Answer ? elt.Pool_Answer.Pool_Answer_Text : ""}</td>
                <td>${elt.Pool_Answer && elt.Pool_Answer.Pool_Question ? elt.Pool_Answer.Pool_Question.Pool_Question_Title : ""}</td>
                <td>${moment(elt.CreatedDateTime).format('DD/MM/YYYY')}</td>
                <td>${elt.Pool_Answer_Text || ""}</td>
              </tr>
            `).join("")}
          </tbody>
        </table>
      </div>
    `;
  
    document.body.appendChild(content); // İçeriği sayfaya ekliyoruz
  
    // İçerik yüklendikten sonra html2canvas'i çalıştırıyoruz
    html2canvas(content).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // A4 genişliğine göre ayarlandı
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save(`${users1.Customer_NameSurname}_report.pdf`);
  
      document.body.removeChild(content); // İşlemden sonra görünmez div'i kaldırıyoruz
    }).catch(error => {
      console.error("PDF oluşturulurken hata oluştu:", error);
    });
  };


  const exportPDFPool = (users1) => {
    // HTML içeriği görünmez bir div içine ekliyoruz
    const content = document.createElement("div");
    content.style.position = "absolute";
    content.style.top = "-9999px"; // Sayfada görünmez
    content.innerHTML = `
      <div style="font-family: Arial, sans-serif;">
        <h2>Kullanıcı Excel</h2>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>K.Adi</th>
              <th>S.Cevap</th>
              <th>Soru</th>
              <th>Tarih</th>
              <th>Y.Cevap</th>
            </tr>
          </thead>
          <tbody>
            ${users1.Users_Complated_Sub_Category.map(elt => `
              <tr>
                <td>${users1.Customer_NameSurname}</td>
                <td>${elt.Question_Sub_Category != null ? elt.Question_Sub_Category.Question_Sub_Category_Text : null}</td>
                <td>${elt.Question_Answer_Range != null ? elt.Question_Answer_Range.Question_Answer_Range_Text : null}</td>
                <td>${moment(elt.Comlated_DateTime).format('DD/MM/YYYY')}</td>
                <td>${ elt.TotalPoints}</td>
              </tr>
            `).join("")}
          </tbody>
        </table>
      </div>
    `;
  
    document.body.appendChild(content); // İçeriği sayfaya ekliyoruz
  
    // İçerik yüklendikten sonra html2canvas'i çalıştırıyoruz
    html2canvas(content).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // A4 genişliğine göre ayarlandı
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save(`${users1.Customer_NameSurname}_report.pdf`);
  
      document.body.removeChild(content); // İşlemden sonra görünmez div'i kaldırıyoruz
    }).catch(error => {
      console.error("PDF oluşturulurken hata oluştu:", error);
    });
  };


  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Users_Profile_Photo ? (
              <div className="avatar-xs">
                {cellProps.Users_Name_Surname != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Users_Name_Surname .charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Users_Profile_Photo}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },

      {
        Header: "İsim",
        accessor: "Users_Name_Surname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Mail Adresi",
        accessor: "Users_MailAddress",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kullanıcı Tipi",
        accessor: "Users_Type.Users_Type_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Telefon Numarası",
        accessor: "Users_WhatsappNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

{cellProps.row.original.Users_Complate_Diagnostic_Form != null ? cellProps.row.original.Users_Complate_Diagnostic_Form.length > 0 ?
<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  exportPDFDiagnostic(userData);
                }}
              >
                <i className="mdi mdi-account-box-outline font-size-18" id="diagnostictooltip" />
                <UncontrolledTooltip placement="top" target="diagnostictooltip">
                 Tanımlama Formu Sonuçları
                </UncontrolledTooltip>
              </Link> : null : null}

{cellProps.row.original.Users_ComplatePool != null ? cellProps.row.original.Users_ComplatePool.length > 0 ?
<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  exportPDF(userData);
                }}
              >
                <i className="mdi mdi-file-document font-size-18" id="documenttooltip" />
                <UncontrolledTooltip placement="top" target="documenttooltip">
                 Anket Sonuçları
                </UncontrolledTooltip>
              </Link> : null : null}


              


              {cellProps.row.original.Users_Complated_Sub_Category != null ? cellProps.row.original.Users_Complated_Sub_Category.length > 0 ?
<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  exportPDFPool(userData);
                }}
              >
                <i className="mdi mdi-comment-question-outline font-size-18" id="poolooltip" />
                <UncontrolledTooltip placement="top" target="poolooltip">
                 Soru Sonuçları
                </UncontrolledTooltip>
              </Link> : null : null}


              
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://parcaburadadmin.stechomeyazilim.info:9097/getUsersType/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
  }, []);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUser());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleQr = () => {
    setModalQr(!modalQr);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setFileName(user.Users_Profile_Photo)
    setIsEdit(true);

    setIsActive(user.Users_Is_Active)
    setCategoryID(user.Users_Type_ID)
    toggle();
  };



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const handleChangeStatusSecond= ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddUser={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

<Modal isOpen={modalQr} toggle={toggleQr}>
                    <ModalHeader toggle={toggleQr} tag="h4">
                      {"Qr"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>

                        <div ref={qrRef}>
        {contactEditQr && (
          <QRCodeCanvas
            value={contactEditQr}
            size={256}           // QR kod boyutu
            bgColor="#ffffff"     // Arka plan rengi
            fgColor="#000000"     // QR kod rengi
            level="H"             // Hata düzeyi (L, M, Q, H seçenekleri)
          />
        )}
      </div>


      <Col>
                          <div className="text-end">
                            <button
                              onClick={() => handleDownload()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Kaydet
                            </button>
                          </div>
                        </Col>

                        </Col>
                      </Row>
                    

                    </ModalBody>
                  </Modal>

              

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                
                            <div className="mb-3">
                              <Label className="form-label">İsim Soyisim</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="İsim Soyisim Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>

                           
                            <div className="mb-3">
                              <Label className="form-label">Telefon</Label>
                              <Input
                                name="phone"
                                label="phone"
                                type="text"
                                placeholder="Telefon Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone &&
                                    validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phone &&
                                validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Mail Adresi</Label>
                              <Input
                                name="mail"
                                label="mail"
                                type="text"
                                placeholder="Mail Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mail || ""}
                                invalid={
                                  validation.touched.mail &&
                                    validation.errors.mail
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mail &&
                                validation.errors.mail ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mail}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Şifre</Label>
                              <Input
                                name="password"
                                label="password"
                                type="text"
                                placeholder="Mail Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>

                            {  getFileName != null ?   <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
              </div> : null}
                            </div>

    
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Tipini Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                  <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Type_Text}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


   
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>

                         

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
